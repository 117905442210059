
.error-body{
    text-align: center;
    width: 80%;
    border: 4px solid black;
    padding: 10px;
    margin-top: 2%;
    margin-bottom: 2%;
}

@media screen and (max-width: 799px) {
    .error-body{
        margin-left: 7%;
    }
}

@media screen and (min-width: 800px) {
    .error-body{
        margin-left: 8%;
    }
}
