/*#region Colors*/

/*#region Base Colors*/

/*Black*/
.color-black-1{
    background-color: black;
}
.color-black-2{
    background-color: rgba(0, 0, 0, 0.781);
}
.color-black-3{
    background-color: rgba(0, 0, 0, 0.219);
}

/*White*/
.color-white-1{
    background-color: white;
}
.color-white-2{
    background-color: rgba(255, 255, 255, 0.404);
}
.color-white-3{
    background-color: rgba(253, 239, 239, 0.685);
}

/*Red*/
.color-red-1{
    background-color: red;
}
.color-red-2{
    background-color: rgba(151, 0, 0, 0.603);
}
.color-red-3{
    background-color: rgba(51, 0, 0, 0.76);
}
/*#endregion */


/*#region Specialized Colors*/
.curtain-color-1{
    background-color: rgb(65, 65, 65);
}
.curtain-color-2{
    background-color: rgb(218, 183, 143);
}

/*portfolio color scheme*/
.portfolio-color-1{
    background-color: #6D623E;
}
.portfolio-color-2{
    background-color: rgba(212, 175, 55, 0.5);
}
.portfolio-color-3{
    background-color: rgba(255, 196, 0, 0.274);
}

/*Shop color scheme*/
.shop-color-1{
    background-color: rgba(91, 143, 137, 0.712);
}
.shop-color-2{
    background-color: rgba(150, 69, 69, 0.5);
}
.shop-color-3{
    background-color: rgba(189, 54, 0, 0.274);
}

/*Language*/
.language-color-1{
    background-color: #3a5041;
}
.language-color-2{

}
.language-color-3{

}

/*Drop down gold*/
.gold-drop-solid{
    background-color: #e0a912c9;
}

/*#endregion */

/*#endregion colors*/