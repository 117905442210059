/*#region Universal*/

/*#region Quote generator*/

.quote-container {
    width: 80%;
    padding: 10px;
    margin-left: 8%;
    margin-top: 2%;
    font-size: 120%;
    text-align: center;
    border: 4px solid black;
}
.quoteButton {
    padding: 6px 8px;
    margin-top: 14px;
    margin-right: 0px;

    background-color: rgba(121, 101, 36, 0.69);
    font-size: large;
    border: 4px solid black;
}
.quoteButton:hover {
    cursor: pointer;
    background-color: rgb(255, 196, 0);
}

.quote-content {
    display: inline-block;
    max-width: 80%;
    margin-top: 5px;
    margin-bottom: 5px;
    flex-wrap: wrap;

    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

@media screen and (max-width: 800px) {
    .quote-container {
        border: 2px solid black;
        font-size: 100%;
    }

    .quoteButton {
        border: 2px solid black;
    }
}
@media screen and (max-width: 500px) {
    .quote-container {
        margin-left: 6%;
    }
}
/*#endregion Quote generator*/

/*#region cat generator*/
.catgen-container{
    width: 80%;
    padding: 10px;
    margin-left: 8%;
    margin-top: 2%;
    margin-bottom: 2%;
    text-align: center;
    border: 4px solid black;
}
.catContainer {
    margin-top: 2%;
    flex-wrap: wrap;
}

.cat-input-container{
    text-align: center;
}

.catInput {
    caret-color: rgb(0, 0, 0);
    width: 30%;
    height: 100%;
    font-size: 20px;
    border: 3px solid black;

    background-color: rgb(182, 182, 182);
    transition: background-color 0.3s linear, border-color 0.3s;
}
.catInput:hover {
    border: 3px solid rgb(19, 19, 19);
    background-color: rgb(216, 216, 216);
}
.catInput:focus {
    outline: none !important;
    border: 3px solid rgb(34, 34, 34);
    background-color: white;
}
.cat-button{
    margin-top: 1%;
    font-size: 20px;
    height: 100%;
    border: 3px solid black;
    cursor: pointer;
    
    background-color: rgb(182, 182, 182);
    transition: background-color 0.3s linear, border-color 0.3s;
}
.cat-button:hover{
    background-color: white;
    border: 3px solid rgb(34, 34, 34);
}

.catImg {
    border: 4px solid black;
    background-color: wheat;
}

@media screen and (max-width: 1200px) {
    .catInput {
        width: 30%;
        height: 100%;
        font-size: 18px;
    }
}
@media screen and (max-width: 1100px) {
    .catInput {
        width: 34%;
    }
}
@media screen and (max-width: 1100px) {
    .catInput {
        width: 38%;
        font-size: 16px;
    }
}
@media screen and (max-width: 800px) {
    .catInput {
        width: 80%;
        height: 30px;
        font-size: 16px;
    }
    .catgen-container{
        border: 2px solid black;
    }
    .catImg {
        border: 2px solid black;
        background-color: wheat;
    }
}
@media screen and (max-width: 600px) {
    
}
@media screen and (max-width: 500px) {
    .catgen-container{
        margin-left: 6%;
        border: 2px solid black;
    }
    .catImg {
        width: 100%;
        height: 100%;
        margin-left: -1%;
    }

    
}
/*#endregion cat generator*/

/*#endregion universal*/

/*#endregion*/