/*#region Universal*/
.testingHead {
    position: fixed;
    background-color: rgb(65, 65, 65);
    width: 100%;
    height: 200px;
    right: 0%;
    top: -10%;
}

.gallery-photo {
    width: 80%;
    height: 80%;
}

/*#region css for slideshow*/

.slideshow-container {
    max-width: 1000px;
    position: relative;
    margin: auto;
}

/* Hide the images by default */
.mySlides {
    display: none;
}

/* Next & previous buttons */
.prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

/* Position the "next button" to the right */
.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */
.text {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}

/* Fading animation */
.fade {
    animation-name: fade;
    animation-duration: 1.5s;
}

@keyframes fade {
    from {
        opacity: .4
    }

    to {
        opacity: 1
    }
}

/*#endregion*/

/* .previous,
.next {
    cursor: pointer;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

.previous:hover,
.next:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.slideshow-container {
    position: relative;
    margin: auto;
}

.mySlides {
    display: none;
}
 */

/*#region boundry parameters*/
@media screen and (max-width: 799px) {
    .picture-gallery-title {
        text-align: center;
        background-color: rgba(255, 196, 0, 0.274);

        width: 80%;
        border: 2px solid black;
        padding: 1px;
        margin-left: 10%;
        margin-top: 2%;
        margin-bottom: 1%;
    }

    .gallery-content {
        text-align: center;
        margin-left: 1%;
        margin-top: 1%;
    }

    .gallery-photo {
        border: 2px solid black;
    }
}

@media screen and (min-width: 800px) {


    .picture-gallery-title {
        text-align: center;
        background-color: rgba(255, 196, 0, 0.274);

        width: 80%;
        border: 4px solid black;
        padding: 10px;
        margin-left: 8%;
        margin-top: 2%;
        margin-bottom: 1%;
    }

    .gallery-content {
        text-align: center;
        margin-top: 1%;
    }

    .gallery-photo {
        border: 4px solid black;
    }
}

/*#endregion*/

/*#endregion*/