.health-title{
    font-size: 20px;
    text-align: center;
    width: 50%;
    border: 4px solid rgb(255, 255, 255);
    padding: 10px;
    margin-left: 25%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.health-body{
    font-size: 20px;
    text-align: center;
    width: 80%;
    border: 4px solid rgb(255, 255, 255);
    padding: 10px;
    margin-left: 10%;
    margin-top: 2%;
    margin-bottom: 2%;
}

/*NavBar*/
.dropbtnHealth {
    caret-color: rgb(0, 0, 0);
    border: none;
    cursor: pointer;
    background-image: url(../../images/dropDownImgHealth.png);
    background-repeat: no-repeat;

    background-color: rgb(255, 255, 255);
    transition: background-color 0.3s linear;

    background-position: 10% 10%;
    height: 40px;
    width: 40px;
}
.dropbtnHealth:hover {
    background-color: #ff0800c9;
}
.project-dropdown-content-health{
    display: none;
    text-align: center;
    background-color: #f1f1f100;
    min-width: 160px;
    box-shadow: 0px 0px 16px 0px rgba(255, 255, 255, 0.623);
    background-color: #e9aaa291;
    z-index: 1;
    
    margin-top: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.show {
    display: block;
}

/*Buttons*/
#link-button-health {
    background-color: rgba(255, 255, 255, 0.616);
    border: 1px solid black;
    font-weight: lighter;
    font-size: 100%;
}
#link-button-health:hover {
    background-color: rgba(255, 255, 255, 0.89);
    border: 1px solid black;
    font-weight: lighter;
    font-size: 100%;
    /*Changes mouse cursor to pointer*/
    cursor: pointer;
}