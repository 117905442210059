/*#region Universal*/

/*#region boundry parameters*/
@media screen and (max-width: 799px){
    .resume-title{
        font-size: 200%;
        font-family: "AlexBrush-Regular";
    }
    .self-portrait {
        width: 138px;
        height: 184px;
        margin-top: 1%;
        border: 2px solid black;
    }
    .lca-certificate{
        margin-top: 1%;
        width: 60%;
        height: 60%;
        border: 2px solid black;
    }
    .dirtymike{
        margin-top: 1%;
        width: 70%;
        height: 70%;
        border: 2px solid black;
    }
    .resume-text {
        border: 2px solid black;
        padding: 10px;
        width: 80%;
        margin-left: 9%;
        margin-top: 2%;
        margin-bottom: 2%;
    }
}
@media screen and (min-width: 800px){
    .resume-title{
        font-size: 400%;
        font-family: "AlexBrush-Regular";
    }
    .self-portrait {
        width: 276px;
        height: 368px;
        margin-top: 1%;
        border: 4px solid black;
    }
    .lca-certificate{
        margin-top: 1%;
        width: 557px;
        height: 394px;
        border: 4px solid black;
    }
    .dirtymike{
        margin-top: 1%;
        width: 539px;
        height: 536px;
        border: 4px solid black;
    }
    .resume-text {
        width: 80%;
        border: 4px solid black;
        padding: 10px;
        margin-left: 9%;
        margin-top: 2%;
        margin-bottom: 1%;
    }
}
/*#endregion*/

/*#region mobile*/

@media screen and (min-width: 500px) and (max-width: 599px) {
    .resume-text {
        margin-left: 8%;
    }
}
@media screen and (min-width: 400px) and (max-width: 499px) {
    .resume-text {
        margin-left: 7%;
    }
}
@media screen and (min-width: 300px) and (max-width: 399px) {
    .resume-text {
        padding: 4px;
        width: 85%;
        margin-left: 6%;
        margin-top: 1%;
        font-size: 16px;
    }
}

/*#endregion*/