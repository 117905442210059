/*#region Universal*/

/*#region Registration*/

@media screen and (min-width: 800px){
    .registration-home{
        margin-left: 25%;
    }
    .reg-title{
        font-size: 40px;
        font-weight: bold;
    }

    .reg-input-field-container{

    }
    .reg-fields{

    }
    .reg-input-field{

    }
    .reg-input-submit{
        background-color: white;
        border: 1px solid rgb(0, 0, 0);
        width: 10%;
        margin-left: 45%;
    }
    .reg-input-submit:hover{
        cursor: pointer;
    }
}

@media screen and (max-width: 799px) {
    
}



/*#endregion */

/*#region misc*/

/*Scroll bar*/
::-webkit-scrollbar {
    width: 20px;
}
::-webkit-scrollbar-track {
    background: #6D623E;
    border: 4px solid black;

}
::-webkit-scrollbar-thumb {
    background: rgba(212, 172, 41, 0.404);
    border: 4px solid black;
}
.resScrollBar {
    width: 1000px;
    height: 1000px;
}

/*Hover*/
::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 196, 0, 0.514);
}

/*Buttons*/
#link-button {
    background-color: rgba(212, 172, 41, 0.404);
    border-style: solid;
    border-color: black;
    font-weight: lighter;
}
#link-button:hover {
    background-color: rgba(255, 196, 0, 0.514);
    border-style: solid;
    border-color: black;
    font-weight: lighter;

    /*Changes mouse cursor to pointer*/
    cursor: pointer;
}

/*placeholder text in input and text area boxes*/
::placeholder {
    color: rgb(64, 64, 64);
    position: relative;
    text-align: center;
}

::-ms-input-placeholder {
    color: rgb(93, 93, 93);
}

.mainHTML {
    caret-color: rgba(0, 0, 0, 0);
}
.br {
    display: block;
    margin-bottom: 0em;
}
#br {
    display: block;
    margin-bottom: 0em;
}
/*Delete below when everything has been updated with class bold-this*/
#bold-this {
    font-weight: bold;
}
.bold-this{
    font-weight: bold;
}
.body {
    /* background-color: rgb(65, 65, 65); */

    -ms-overflow-style: none;
    scrollbar-width: none;
    text-align: center;
}
.divider-line {
    background-color: black;
    height: 3px;
    border: none;
}
.break-container {
    width: 50%;
}

/*#endregion misc*/

/*#region leftbreak*/

/*#region Drop down menu*/

/* Dropdown Button */
.dropbtn {
    caret-color: rgb(0, 0, 0);
    border: none;
    cursor: pointer;
    background-image: url(../images/dropDownImg.png);
    background-repeat: no-repeat;

    background-color: rgb(0, 0, 0);
    transition: background-color 0.3s linear;

    background-position: 10% 10%;
    height: 40px;
    width: 40px;
}

.dropbtn span {
    display: none;
}

/* Dropdown button on hover & focus */
.dropbtn:hover {
    background-color: #e0a912c9;
}
/* Dropdown Content (Hidden by Default) */
.project-dropdown-content {
    display: none;
    text-align: center;
    background-color: #f1f1f100;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(255, 166, 0, 0.2);
    background-color: #6d623e;
    z-index: 1;
    
    padding-top: 10px;
    padding-bottom: 10px;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
    display: block;
}

/*Content Buttons*/
#link-button {
    background-color: rgba(212, 172, 41, 0.404);
    border: 1px solid black;
    font-weight: lighter;
    font-size: 100%;
}

#link-button:hover {
    background-color: rgba(255, 196, 0, 0.514);
    border: 1px solid black;
    font-weight: lighter;
    font-size: 100%;
    /*Changes mouse cursor to pointer*/
    cursor: pointer;
}

/*#endregion dropdown*/

/*Hide content when scrolling up*/
.testingHead {
    position: fixed;
    width: 100%;
    height: 200px;
    right: 0%;
    top: -10%;
}
.leftbreak-background-setter {
    width: 100%;
    height: 100%;
    margin-top: 1%;
}


/*#endregion leftbreak*/

/*#region rightbreak*/
.right-content-wide{
    text-align: center;
    width: 80%;
    border: 4px solid black;
    padding: 10px;
    margin-left: 10%;
    margin-top: 2%;
    margin-bottom: 2%;
}
.right-content{
    text-align: center;
    width: 40%;
    border: 4px solid black;
    padding: 10px;
    margin-left: 30%;
    margin-top: 2%;
    margin-bottom: 2%;
}
/*#endregion rightbreak*/

/*#region bound width parameters*/

/*Under 800px*/
@media screen and (max-width: 799px) {
    .default-comp{
        font-size: 20px;
        position: absolute;
        width: 80%;
        margin-left: 10%;
        border: 3px solid black;
    }
    .default-button{
        font-size: 30px;
        position: absolute;
        width: 10%;
        margin-left: 43%;
        border: 3px solid black;
    }

    .leftbreak {
        position: fixed;
        /* background-color: rgba(212, 175, 55, 0.3); */
        width: 98%;
        height: 125px;
        margin-top: -4px;
        margin-left: -11px;
        border: 1px solid black;

        text-align: center;
    }

    /*Right Break: content side*/
    .rightbreak {
        z-index: -1;
        /* background-color: rgba(212, 175, 55, 0.5); */
        width: 98%;
        margin-top: 128px;
        margin-left: -11px;
        position: absolute;
        border: 1px solid black;
        text-align: center;
    }

    .home-title {
        width: 80%;
        border: 2px solid black;
        padding: 10px;
        margin-left: 7%;
        margin-top: 2%;
        margin-bottom: 2%;

        font-family: "Abasalom";
        font-size: 200%;

        /* background-color: rgba(255, 196, 0, 0.274); */
    }
    .home-container{
        width: 80%;
        border: 2px solid black;
        padding: 10px;
        margin-left: 7%;
        margin-top: 2%;
        margin-bottom: 2%;
        /* background-color: rgba(255, 196, 0, 0.274); */

        font-family: "Abasalom";
        font-size: 100%;
    }
    .home-points{
        margin-left: -6%;
        margin-top: -1%;
        display: inline-block;
    }

    .divider-line {
        background-color: black;
        height: 0px;
        border: none;
    }

    .contact-info {
        text-align: center;
        font-size: 100%;
        margin-top: 40px;
    }
    .resources {
        /*Hide this for it takes up to much space*/
        font-size: 0%;
    
        margin-right: 400px;
        margin-top: -35px;
    }

    /*Drop down button*/
    .my-projects {
        text-align: center;
        font-size: 100%;
        margin-top: -15px;
        margin-left: -80%;
    }

    .project-dropdown-content {
        margin-top: 0px;
        margin-left: 80%;
        /*needed to fix the nav bar to the left side of the screen*/
        position: absolute;
    }
}
/*Over 800px*/
@media screen and (min-width: 800px) {
    .default-comp{
        font-size: 30px;
        position: absolute;
        width: 80%;
        margin-left: 10%;
        border: 3px solid black;
    }
    .default-button{
        font-size: 30px;
        position: absolute;
        width: 10%;
        margin-left: 43%;
        border: 3px solid black;
    }


    /*Left Break: Contact info, resources, my links, project links*/
    .leftbreak {
        position: fixed;
        /* background-color: rgba(212, 175, 55, 0.3); */
        width: 20%;
        margin-left: 0%;
        margin-top: 0%;
        height: 96%;

        border: 4px solid black;
        /*For Modals to be displayed ontop of right break*/
        z-index: 1;
    }

    .home-title {
        width: 80%;
        border: 4px solid black;
        padding: 10px;
        margin-left: 10%;
        margin-top: 2%;
        margin-bottom: 2%;

        font-family: "Abasalom";
        font-size: 300%;

        /* background-color: rgba(255, 196, 0, 0.274); */
    }
    .home-container{
        width: 80%;
        border: 4px solid black;
        padding: 10px;
        margin-left: 10%;
        margin-top: 2%;
        margin-bottom: 2%;
        /* background-color: rgba(255, 196, 0, 0.274); */

        font-family: "Abasalom";
        font-size: 130%;
    }
    .home-points{
        margin-left: -5%;
        margin-top: -1%;
        display: inline-block;
    }

    .contact-info {
        text-align: center;
        font-size: 120%;
    }

    .resources {
        text-align: center;
    }

    .my-links {
        text-align: center;
        font-size: 150%;
    }

    .my-projects {
        text-align: center;
        font-size: 120%;

        margin-left: 0%;
        margin-top: 10px;
        margin-bottom: 0px;
    }

    .divider-line {
        background-color: black;
        height: 3px;
        border: none;
    }

    .rightbreak {
        /* background-color: rgba(212, 175, 55, 0.5); */
        position: absolute;
        width: 75%;
        margin-left: 22%;
        border: 4px solid black;
    }
}

/*#endregion bound width parameters*/

/*#endregion universal*/

/*#region phone*/
@media screen and (min-width: 500px) and (max-width: 599px) {
    /*To stop all the content from shifting to the left*/
    .leftbreak {
        margin-left: -12px;
    }
    .rightbreak{
        margin-left: -12px;
    }

    .home-points{
        margin-left: -8%;
    }
}
@media screen and (min-width: 400px) and (max-width: 499px) {

    /*To stop all the content from shifting to the left*/
    .leftbreak {
        margin-left: -13px;
    }
    .rightbreak{
        margin-left: -13px;
    }

    .home-points{
        margin-left: -10%;
    }
}
@media screen and (min-width: 300px) and (max-width: 399px) {

    /*To stop all the content from shifting to the left*/
    .leftbreak {
        margin-left: -14px;
    }
    .rightbreak{
        margin-left: -14px;
    }

    .home-points{
        margin-left: -13%;
    }

    .default-comp{
        font-size: 20px;
        position: absolute;
        width: 80%;
        margin-left: 5%;
        border: 3px solid black;
    }
}
/*#endregion */