/*#region Universal*/

/*#region BMI CALC*/
.bmi-explanation {
    text-align: center;
    width: 80%;
    border: 4px solid black;
    padding: 10px;
    margin-left: 10%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.bmi-content {
    text-align: center;
    width: 40%;
    border: 4px solid black;
    padding: 10px;
    margin-left: 30%;
    margin-top: 2%;
    margin-bottom: 1%;
}

.bmi-inputbox {
    caret-color: rgb(0, 0, 0);
    width: 26%;
    font-size: 20px;
    text-align: center;

    outline: none !important;
}

#uomHeight, #uomWeight{
    font-size: 20px;
}

/*#endregion bmicalc*/

/*#region toggleswitch*/
.checkbox-container {
    display: flex;
    width: 50%;
    margin-left: 25%;
    justify-content: space-evenly;
}
/*The new switch*/
.toggle-switch {
    margin-bottom: 3%;
    position: relative;
    display: inline-flex;
    width: 50px;
    height: 30px;
    top: 10px;
    right: 15px;
    /*Changes mouse cursor to pointer*/
    cursor: pointer;
}
.toggle-switch input[type="checkbox"] {
    /*Hides default check box*/
    display: none;
}

/*new switch positioning*/
.toggle-switch .switch {
    position: absolute;
    cursor: pointer;
    background-color: #6D623E;
    border-radius: 0%;
    border: 3px solid black;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    transition: background-color 0.6s ease;
}

.toggle-switch .switch::before {
    position: absolute;
    content: "";
    left: 1px;
    top: 1px;
    right: 0px;
    width: 21px;
    height: 20px;
    background-color: rgba(212, 172, 41, 0.404);
    border: 1px solid black;

    transition: transform 0.3s ease;
}
/*Checked Switch*/
.toggle-switch input[type="checkbox"]:checked+.switch::before {
    position: absolute;
    transform: translateX(18px);
    background-color: rgba(255, 196, 0, 0.514);
}
.toggle-switch input[type="checkbox"]:checked+.switch {
    position: absolute;
    background-color: rgba(212, 172, 41, 0.404);
}

.metric-placeholder, .imperial-placeholder{
    position: absolute;
    font-size: 20px;
    margin-top: 12px;
}
.metric-placeholder{
    margin-left: 80px;
}
.imperial-placeholder{
    margin-right: 160px;
}
/*#endregion toggleswitch*/

/*#endregion universal*/

@media screen and (max-width: 1700px) {
    .checkbox-container{
        margin-left: 30%;
    }
}
@media screen and (max-width: 800px) {
    .bmi-explanation {
        border: 2px solid black;
    }
    .bmi-content {
        width: 60%;
        border: 2px solid black;
        margin-left: 20%;
    }
    
}
@media screen and (max-width: 600px) {
    .checkbox-container{
        margin-left: 30%;
    }
    #uomHeight, #uomWeight{
        font-size: 16px;
    }
    .metric-placeholder, .imperial-placeholder{
        font-size: 16px;
    }
}
@media screen and (max-width: 500px) {

    .bmi-inputbox {
        font-size: 18px;
    }

    #uomHeight, #uomWeight{
        font-size: 16px;
    }
    

    .toggle-switch .switch {
        border: 2px solid black;
    }
    .switch{
        margin-top: 2px;
    }

    .metric-placeholder, .imperial-placeholder{
        font-size: 16px;
        margin-top: 16px;
    }
    .metric-placeholder{
        margin-left: 70px;
    }
    .imperial-placeholder{
        margin-right: 150px;
    }
}
@media screen and (max-width: 400px) {
    .bmi-inputbox {
        font-size: 16px;
    }
    .bmi-inputbox-container{
        margin-left: 9%;
    }
    .checkbox-container{
        margin-left: 34%;
    }
}